<template>
<div class="site-main">
  <div class="site-content"><router-view></router-view></div>
  <AlarmNotify @shown="showAlarmList" />
  <ModalList v-model="showAlarmModal" />
</div>
</template>
<script>
import {mapState} from 'vuex'
import AlarmNotify from '@/views/alarm/Notify'
import ModalList from '@/views/alarm/ModalList'
export default {
  name: 'Home',
  components: {
    AlarmNotify,
    ModalList,
  },
  data(){
    return {
      timeout: null,
      showAlarmModal: false,
    }
  },
  created: function () {
    this.$store.commit('auth/init');
    // console.log('first page created');
    if(!this.user || !this.token){
      this.$Message.info('登录已过期');
      this.$router.replace('/login');
      return;
    }
  },
  computed: {
    ...mapState('auth', ['token', 'user', 'appType', 'customerId']),
  },
  watch: {
    appType(){
      this.debounceRefreshAgent();
    },
  },
  mounted: function(){
    this.debounceRefreshAgent();
  },
  methods: {
    showAlarmList: function(){
      this.showAlarmModal = true;
    },
    debounceRefreshAgent: function(){
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.refreshAgent, 500);
      this.$store.commit('cmd/initOldLogStatus');
    },
    refreshAgent: function(){
      if(!this.token)return;
      if(this.appType == '')return;
      this.$store.dispatch('common/getDeviceTypes');
      this.$store.dispatch('common/getBigSmallTypes');
      this.$store.dispatch('auth/switchAppCust', {app: this.appType, cust: this.customerId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }
      });
    }
  },
}
</script>
<style>
.site-main{
  width: 100%;
  height: 100%;
  /* border: solid 1px red; */
  position: relative;
  overflow: hidden;
}
.site-content{
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* border: solid 1px blue; */
  display: flex;
  flex-direction: column;
}
</style>